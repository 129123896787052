import { createStore } from 'vuex';
import axios from "axios";
import VueCookie from "vue-cookie";

export default createStore({
  state: {
    token: VueCookie.get("token") || null,
    userEmail: VueCookie.get("userEmail") || null,
    accessToken: VueCookie.get("accessToken") || null,
    questions: [],
    suggestionQuestions: [],
    selectedQuestion: {},
    selectedQuestionResponses: [],
    searchApp: '',
    filterKey: '',
    currentApp: 'all',
    showAll: true,
    showAnswered: false,
    showUnanswered: false,
    loggedIn: false,
    first_name: '',
    // trainings
    trainings: []
  },
  getters: {
    loggedIn(state) {
      return state.accessToken !== null && state.userEmail !== null;
    },
  },
  mutations: {
    retrieveApiToken(state, token) {
      state.token = token;
    },
    retrieveAccessToken(state, accessToken){
      state.accessToken = accessToken
    },
    retrieveUserEmail(state, userEmail){
      state.userEmail = userEmail
    },
    destroyToken(state) {
      state.token = null;
    },
    destroyAccessToken(state) {
      state.accessToken = null;
      state.userEmail = ''
    },
    authenticate(state, payload){
      state.first_name = payload
      state.loggedIn = true
    },
    setShowLoader(state, value) {
      state.showLoader = value;
    },
    logout(state){
      state.loggedIn = false
      state.first_name = ''
    },
    setQuestions(state, payload){
      state.questions = payload
      state.suggestionQuestions = payload
    },
    filterQuestions(state, payload){
      state.questions = payload
    },
    setSelectedQuestion(state, payload){
      state.selectedQuestion = payload
    },
    filterSelectedQuestion(state, payload){
      state.selectedQuestion = payload
    },
    toggleFilterKey(state, payload){
      state.filterKey = payload
    },
    toggleshowAll(state, payload){
      state.showAll = payload
    },
    toggleshowAnswered(state, payload){
      state.showAnswered = payload
    },
    toggleshowUnanswered(state, payload){
      state.showUnanswered = payload
    },
    setSelectedQuestionResponses(state, payload){
      const responsesWithModal = payload.map(response => ({ ...response, 
        showResponseModal: false, 
        showImageModals: false,
        currentImageIndex: 0
       }));
      state.selectedQuestionResponses = responsesWithModal;
    },
    toggleResponseModal(state, responseId) {
      const response = state.selectedQuestionResponses.find(response => response.id === responseId);
      if (response) {
        response.showResponseModal = !response.showResponseModal;
      }
    },
    toggleImagesModal(state, responseId) {
      const response = state.selectedQuestionResponses.find(response => response.id === responseId);
      if (response) {
        response.showImageModals = !response.showImageModals;
      }
    },
    toggleNextImage(state, responseId) {
      const response = state.selectedQuestionResponses.find(response => response.id === responseId);
      if (response) {
        response.currentImageIndex = response.currentImageIndex + 1;
      }
    },
    togglePrevImage(state, responseId) {
      const response = state.selectedQuestionResponses.find(response => response.id === responseId);
      if (response) {
        response.currentImageIndex = response.currentImageIndex - 1;
      }
    },
    updateSelectedQuestionResponses(state, payload){
      state.selectedQuestionResponses.push(payload)
    },
  },
  actions: {
    // register api token
    retrieveApiToken(context) {
      return new Promise((resolve, reject) => {
        axios
          .post("/wb-api-auth/")
          .then((response) => {
            const token = response.data.token;
            context.commit("retrieveApiToken", token);
            VueCookie.set("token", token, "1D");
            resolve(response);
          })
          .catch((error) => {
            console.log('Error retrieving token');
            reject(error);
          });
      });
    },

    // remove token
    destroyToken(context) {
      if (context.getters.loggedIn) {
        return new Promise((resolve) => {
          context.commit("destroyToken");
          VueCookie.delete("token");
          resolve();
        });
      }
    },


    // authenticate user
    authenticate(context, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post("/wb-api-user-auth/", {
            'user-name': credentials.email,
            'user-password': credentials.password,
            'token': "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ3aW5ndWJveC5jb20iLCJhdWQiOiJwYXlyb2xsIiwid2lkIjoiMjk4ODkiLCJpYXQiOjE2OTUzODI0ODh9.hgjUAXYx4tjeFHPn0BaxrHV_ysicagTvO057hAqKPU0"
          })
          .then((response) => {
            if (response.data.access_token){
              const token = response.data.access_token;
              const email = response.data.email;
              context.commit("retrieveAccessToken", token);
              context.commit("retrieveUserEmail", email);
              VueCookie.set("accessToken", token, "1D");
              VueCookie.set("userEmail", email, "1D");
              resolve(response);
            }
            else{
              // const token = null;
              // const email = null;
              // context.commit("retrieveAccessToken", token);
              // VueCookie.set("accessToken", token, "1D");
              // VueCookie.set("userEmail", email, "1D");
              resolve(response);
            }
          })
          .catch((error) => {
            console.log("Error authenticating user");
            reject(error);
          });
      });
    },

    // logout user
    destroyAccessToken(context) {
      if (context.getters.loggedIn) {
        return new Promise((resolve) => {
          context.commit("destroyAccessToken");
          VueCookie.delete("accessToken");
          VueCookie.delete("userEmail");
          resolve();
        });
      }
    },

    toggleLoader({ commit }, value) {
      commit('setShowLoader', value);
    },

    logout(context){
      context.commit('logout')
    },

    newQuestion(context, question) {
      const formData = new FormData();
      formData.append('question_title', question.question_title);
      formData.append('question_description', question.question_description);
      formData.append('asked_by', context.state.userEmail);
      formData.append('app', question.related_app);
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + context.state.accessToken;
      return new Promise((resolve, reject) => {
        for (var i = 0; i < question.images.length; i++) {
          let image = question.images[i];
          // console.log('image to append:' + image)
          formData.append('uploaded_images', image);
        }
      
        axios.post('/question/', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });      
    },

    getQuestions(context){
      return new Promise((resolve, reject) => {
        axios.get('/question/')
        .then((response) => {
          context.commit('setQuestions', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    filterQuestions(context, app){
      return new Promise((resolve, reject) => {
        axios.get('/question/?search=' + app)
        .then((response) => {
          context.commit('filterQuestions', response.data)
          // console.log(app)
          // console.log(response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    toggleFilterKey(context, value){
      context.commit('toggleFilterKey', value)
    },

    toggleshowAll(context, value){
      context.commit('toggleshowAll', value)
    },

    toggleshowAnswered(context, value){
      context.commit('toggleshowAnswered', value)
    },

    toggleshowUnanswered(context, value){
      context.commit('toggleshowUnanswered', value)
    },

    getSelectedQuestion(context, id){
      return new Promise((resolve, reject) => {
        axios.get('/question/' + id + '/')
        .then((response) => {
          context.commit('setSelectedQuestion', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    getSelectedQuestionResponses(context, id){
      return new Promise((resolve, reject) => {
        axios.get('/response/question_id/' + id)
        .then((response) => {
          context.commit('setSelectedQuestionResponses', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    toggleLike(context, answer_id){
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + context.state.accessToken;
      return new Promise((resolve, reject) => {
        axios.post('/like/', {
          answer_id: answer_id,
          liked_by: context.state.userEmail
        })
        .then((response) => {
          // context.commit('updateAnswersOnLikeToggle', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    parentAnswer(context, answer) {
      const formData = new FormData();
      formData.append('answer', answer.answer);
      formData.append('answered_by', context.state.userEmail);
      formData.append('question', context.state.selectedQuestion.id);

      axios.defaults.headers.common["Authorization"] =
        "Bearer " + context.state.accessToken;
      return new Promise((resolve, reject) => {
        for (var i = 0; i < answer.images.length; i++) {
          let image = answer.images[i];
          console.log('image to append:' + image)
          formData.append('uploaded_images', image);
        }      
        axios.post('/response/', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(response => {
            // context.commit('updateSelectedQuestionResponses', response.data)
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });      
    },

    childAnswer(context, answer) {
      const formData = new FormData();
      formData.append('answer', answer.answer);
      formData.append('answered_by', context.state.userEmail);
      formData.append('question', context.state.selectedQuestion.id);
      formData.append('parent_response', answer.parent_response);

      axios.defaults.headers.common["Authorization"] =
        "Bearer " + context.state.accessToken;
      return new Promise((resolve, reject) => {
        for (var i = 0; i < answer.images.length; i++) {
          let image = answer.images[i];
          console.log('image to append:' + image)
          formData.append('uploaded_images', image);
        }      
        axios.post('/response/', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(response => {
            // context.commit('updateSelectedQuestionResponses', response.data)
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });      
    },
    toggleResponseModal(context, responseId) {
      context.commit('toggleResponseModal', responseId);
    },
    toggleImagesModal(context, responseId) {
      context.commit('toggleImagesModal', responseId);
    },
    toggleNextImage(context, responseId) {
      context.commit('toggleNextImage', responseId);
    },
    togglePrevImage(context, responseId) {
      context.commit('togglePrevImage', responseId);
    },
  },
  modules: {
  }
})
