<template>
  <div class="container-fluid">
    <div class="notification" style="background-color: rgb(12, 176, 241); color: white; margin-top: -20px;">
      <div class="header">
        <h1 class="title pt-5">Upcoming Trainings</h1>
        <h2 class="subtitle">Stay updated with the latest training sessions. Enhance your skills with our expert-led training.</h2>
        <button class="button is-dark" @click="showModal = true">Get Notified</button>
      </div>
    </div>

    <div class="filters m-5">
      <button class="button is-primary" @click="filterTrainings('upcoming')">Upcoming Trainings</button>
      <button class="button is-secondary" @click="filterTrainings('past')">Past Trainings</button>
    </div>

    <div v-if="loadingData" class="m-5">
      <div class='gfg g1'></div>
      <div class='gfg g2'></div>
      <div class='gfg g3'></div>
      <div class='gfg g4'></div>
      <div class='gfg g5'></div>
    </div>

    <div class="columns is-multiline m-5">
      <div class="column is-one-quarter" v-for="training in filteredTrainings" :key="training.id">
        <div class="card" :class="{ 'expanded': selectedTraining.id === training.id }">
          <div class="card-image">
            <figure v-if="!isPastTraining(training.date)" class="image is-3by2">
              <img :src="training.images[0].image" alt="Training Image">
            </figure>
            <div v-if="isPastTraining(training.date)" v-html="training.embedded_code"></div>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                <p class="title is-4">{{ training.title }}</p>
                <p class="subtitle is-6">{{ formatDate(training.date) }} at {{ formatTime(training.time) }}</p>
                <p><strong>Location:</strong> {{ training.location }}</p>
              </div>
            </div>
            <div class="content" style="display: flex; justify-content: space-between; align-items: center;">
              <button class="button is-small is-info" @click="toggleDetails(training)">{{ selectedTraining.id === training.id ? 'Hide Details' : 'View More Details' }}</button>
              <a v-if="!isPastTraining(training.date)" target="_blank" :href="training.training_link" class="button is-primary is-small">Register</a>
              <button v-else @click="viewRecording(training)" class="button is-primary is-small">View Recording</button>
            </div>
          </div>
          <div v-if="selectedTraining.id === training.id" class="expanded-content">
            <p v-html="training.extended_description"></p>
            <hr>
            <p v-html="training.learning_outcomes"></p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="filteredTrainings.length === 0" class="m-5">
      <p>No trainings available</p>
      <!-- <button class="button is-dark mt-5" @click="showModal = true">Get Notified</button> -->
    </div>

    <!-- Modal for Get Notified -->
    <div class="modal" :class="{ 'is-active': showModal }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Subscribe to Get Notifications</p>
          <button @click="showModal = false" class="delete ml-2" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <form class="form" @submit.prevent="subscribe">
            <div v-if="isIncorrectCredentials" class="notification is-danger is-light">
              <button @click="isIncorrectCredentials = false" class="delete"></button>
              Email Already Registered!
            </div>
            <div class="field">
              <label class="label">Email</label>
              <div class="control">
                <input v-model="email" required class="input" type="email" placeholder="Enter your email address">
              </div>
            </div>
            <div class="field">
              <div class="control">
                <label class="checkbox">
                  <input v-model="isSubscribed" type="checkbox" required>
                  I am sure I want to receive trainings' notifications.
                </label>
              </div>
            </div>
            <footer class="modal-card-foot">
              <button v-if="!isAuthenticating" class="button is-success">Subscribe</button>
              <button v-if="isAuthenticating" class="button is-success" disabled>Subscribing...</button>
            </footer>
          </form>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from 'vue-toast-notification';

export default {
  data() {
    return {
      trainings: [],
      filteredTrainings: [],
      selectedTraining: {},
      showModal: false,
      email: '',
      isSubscribed: false,
      isIncorrectCredentials: false,
      isAuthenticating: false,
      loadingData: true,
    };
  },
  methods: {
    async fetchTrainings() {
      try {
        const response = await axios.get('https://community.wingubox.com/api/v1/trainings/');
        this.trainings = response.data;
        this.filterTrainings('upcoming');
        this.loadingData = false;
      } catch (error) {
        console.error('Error fetching trainings:', error);
      }
    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    formatTime(time) {
      const options = { hour: '2-digit', minute: '2-digit' };
      return new Date(`1970-01-01T${time}Z`).toLocaleTimeString(undefined, options);
    },
    filterTrainings(filter) {
      const currentDate = new Date();
      if (filter === 'upcoming') {
        this.filteredTrainings = this.trainings.filter(training => new Date(training.date) >= currentDate);
      } else if (filter === 'past') {
        this.filteredTrainings = this.trainings.filter(training => new Date(training.date) < currentDate);
      }
    },
    isPastTraining(date) {
      return new Date(date) < new Date();
    },
    toggleDetails(training) {
      if (this.selectedTraining.id === training.id) {
        this.selectedTraining = {};
      } else {
        this.selectedTraining = training;
      }
    },
    async subscribe() {
      this.isAuthenticating = true;
      // this.isIncorrectCredentials = false;
      const toast = useToast();

      try {
        await axios.post('https://community.wingubox.com/api/v1/registered-emails/', {
          email: this.email,
          is_subscribed: this.isSubscribed,
        });
        
        this.isAuthenticating = false;
        this.showModal = false;
        this.email = '';
        this.isSubscribed = false;

        toast.success('Subscription successful!');

      } catch (error) {
        this.isAuthenticating = false;
        toast.error('Error subscribing: ' + error.response.data.email);
      }
    },
    viewRecording(training) {
      const toast = useToast();

      if (training.youtube_link) {
        window.open(training.youtube_link, '_blank');
      } else {
        toast.error('Recording Unavailable!');
      }
    }
  },
  created() {
    this.fetchTrainings();
  }
};
</script>


<style scoped>
@import 'bulma/css/bulma.min.css';
@import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
@import 'material-icons/iconfont/material-icons.css';

.container-fluid {
  margin-top: -20px;
  padding-top: 4rem;
}

.filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.card {
  margin-bottom: 2rem;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.card img {
  border-radius: 8px 8px 0 0;
  object-fit: cover;
  width: 100%;
  height: 200px;
}

.card-content {
  padding: 1rem;
}

.card.expanded {
  max-height: none;
  transition: max-height 0.5s ease-in-out;
}

.expanded-content {
  padding: 1rem;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  margin-top: 1rem;
}

.columns .column.is-one-quarter {
  flex: 0 0 25%;
  max-width: 25%;
}

@media (max-width: 1024px) {
  .columns .column.is-one-quarter {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media (max-width: 768px) {
  .columns .column.is-one-quarter {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 480px) {
  .columns .column.is-one-quarter {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.gfg {
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  left: calc(50% - 1em);
  border-radius: 1em;
  transform-origin: 1em 2em;
  animation: rotate;
  animation-iteration-count: infinite;
  animation-duration: 1.8s;
}
</style>