<template>
  <div>
    <nav class="navbar has-shadow is-fixed-top" role="navigation" aria-label="dropdown navigation">
      <div class="ml-5 navbar-brand">
        <!-- logo -->
        <a class="navbar-item" href="https://apps.wingubox.com">
          <img src="./assets/wingubox_logo.png" alt="Bulma: a modern CSS framework based on Flexbox" width="130" height="50">
        </a>

        <!-- burger -->
        <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" @click="isOpen = !isOpen" v-bind:class="{'is-active': isOpen}">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>     
      </div>

      <!-- start of navbar menus -->
      <div class="ml-5 navbar-menu" v-bind:class="{'is-active': isOpen}">
        <div class="navbar-item">
          <router-link to="/" exact>Community Home</router-link>
        </div>

        <div class="navbar-item">
          <router-link to="/trainings">Trainings</router-link>
        </div>

        <div v-if="!this.loggedIn" class="navbar-item navbar-end">
          <a target="_blank" href="https://apps.wingubox.com/products">
            <button class="button">Join</button>
          </a>
        </div>

        <div v-if="this.loggedIn" class="navbar-item navbar-end">
          <a>
            <button @click="logout" class="button">Logout</button>
          </a>
        </div>
      </div>
    </nav>
    <div v-if="showLoader" class="progress-container">
      <progress class="progress is-small is-primary custom-progress" max="100">15%</progress>
    </div>
  </div>
  <router-view/>
  <div class="notification mt-5 custom-footer">
      <p class="mt-4">© Copyright {{ currentYear }}, All Rights Reserved by <a target="_blank" href="https://apps.wingubox.com">Wingubox Ltd</a></p>
  </div>
</template>

<script>
export default {
   data: function() {
       return {
           isOpen: false,
           showBlogDropDown: false,
           showAboutDropDown: false,
           showProductsDropDown: false,
           showSupportDropDown: false,
           currentYear: ''
       }
   },

   mounted() {
      this.getCurrentYear();
    },

    computed: {
      showLoader() {
        return this.$store.state.showLoader;
      },
      loggedIn() {
        return this.$store.getters.loggedIn;
      },
    },

   methods: {
    toggleBlogDropDown(){
      this.showBlogDropDown = !this.showBlogDropDown
    },
    toggleAboutDropDown(){
      this.showAboutDropDown = !this.showAboutDropDown
    },
    toggleProductsDropDown(){
      this.showProductsDropDown = !this.showProductsDropDown
    },
    toggleSupportDropDown(){
      this.showSupportDropDown = !this.showSupportDropDown
    },
    getCurrentYear() {
      const now = new Date();
      this.currentYear = now.getFullYear();
    },
    handleResize() {
      if (window.innerWidth >= 1200) {
        this.showBlogDropDown = false;
        this.showAboutDropDown = false;
        this.showProductsDropDown = false;
        this.showSupportDropDown = false;
      } else if (window.innerWidth >= 768) {
        this.showBlogDropDown = false;
        this.showAboutDropDown = false;
        this.showProductsDropDown = false;
        this.showSupportDropDown = false;
      } else {
        this.showBlogDropDown = false;
        this.showAboutDropDown = false;
        this.showProductsDropDown = false;
        this.showSupportDropDown = false;
      }
    },
    logout(){
      this.$store.dispatch('destroyAccessToken')
      .then(this.$router.push("/"))
    }
   }
}
</script>

<style scoped>
@import 'bulma/css/bulma.min.css';
.custom-footer{
    /* background-color: rgb(12, 176, 241);
    color: white;  */
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    justify-content: center;
  }

  .progress-container {
    position: fixed;
    top: 55px;
    left: 0;
    width: 100%;
    z-index: 1000;
  }

  .custom-progress {
    border-radius: 0;
    background-color: #e0e0e0;
    height: 4px;
  }

  /* Global or scoped styles */
  a {
    color: rgb(25, 22, 22);
    text-decoration: none;
  }

  a.router-link-active {
    color: #017AC2;
  }

</style>
