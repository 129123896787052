import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from "axios";
import CKEditor from '@ckeditor/ckeditor5-vue';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueToast from 'vue-toast-notification';
import { createHead } from '@unhead/vue';

axios.defaults.baseURL = "https://community.wingubox.com/api/v1"; // Production
// axios.defaults.baseURL = "http://127.0.0.1:8000/api/v1"; // Development

const appInstance = createApp(App);
const head = createHead();
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueToast);
appInstance.use(CKEditor);
appInstance.use(head);
appInstance.config.globalProperties.$http = axios;
appInstance.mount("#app");

router.beforeEach((to, from, next) => {
  store.dispatch('toggleLoader', true); // Show the loader

  // Delay the navigation to simulate loading
  setTimeout(() => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (!store.getters.loggedIn) {
        next({
          name: "home",
        });
      } else {
        // Successfully authenticated
        store.dispatch('toggleLoader', false);
        next();
      }
    } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
      if (store.getters.loggedIn) {
        next({
          name: "home",
        });
      } else {
        // Visitor access
        store.dispatch('toggleLoader', false);
        next();
      }
    } else {
      // No specific guard, proceed with navigation
      store.dispatch('toggleLoader', false);
      next();
    }
  }, 1500);
});
