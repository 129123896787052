import { createRouter, createWebHistory } from 'vue-router'
import CommunityView from '../views/CommunityView.vue'
import TrainingsView from '@/views/TrainingsView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: CommunityView,
    meta: { 
      prefetch: true,
      title: 'Wingubox: Community'
    }
  },
  {
    path: '/ask-question',
    name: 'ask-question',
    component: () => import('../views/AskQuestionView.vue'),
    meta: { 
      prefetch: true,
      requiresAuth: true,
      title: 'Ask a Question - Wingubox'
    }
  },
  {
    path: '/:title/:id',
    name: 'asked-questions',
    component: () => import('../views/AskedQuestionView.vue'),
    meta: { 
      prefetch: true,
      title: 'Question Details - Wingubox'
    }
  },
  {
    path: '/trainings',
    name: 'trainings',
    component: TrainingsView,
    meta: { 
      prefetch: true,
      title: 'Trainings - Wingubox'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // Check if the current route has a title parameter
  const routeTitle = to.params.title;
  
  if (routeTitle) {
    // Use the route's title parameter for the document title
    document.title = `Wingubox: Community - ${routeTitle}`;
  } else {
    // Use the meta title or fallback to 'Wingubox'
    document.title = to.meta.title || 'Wingubox';
  }
  
  next();
});

export default router