<template>
    <div class="container-fluid">
        <div class="notification" style="background-color: rgb(12, 176, 241); color: white; margin-top: -20px;">
            <h1 class="title pt-5">Explore Wingubox Community</h1>
            <h2 class="subtitle">Get answers regarding Wingubox from our community. Your question is either already answered
                or you will receive timely response from the community.
            </h2>
            <div class="columns is-vcentered">
                <div class="column is-three-quarters">
                    <div class="control is-medium" :class="{'is-loading': searchKey !== ''}">
                        <p class="control has-icons-left">
                            <input v-model="searchKey" class="input is-medium" type="text" placeholder="Search for Anything Wingubox">
                            <span class="icon is-small is-left">
                                <i class="material-icons">search</i>
                            </span>
                        </p>
                    </div>
                    <div>
                        
                    </div>
                    <ul class="auto-suggest suggestion-list" style="background-color: aliceblue; color: black; list-style-type: none; padding: 0;">
                        <li v-for="question in suggestedQuestions" :key="question.id">
                        <a @click="moveToQuestion(question.question_title, question.id)" class="auto-suggest-link" style="text-decoration: none; display: block; padding: 10px;">
                            <h2 style="margin: 0;">{{ truncateDescription(question.question_title, 150) }}</h2>
                        </a>
                        </li>
                    </ul>
                </div>
                <p class="mt-1">OR</p>
                <div class="column">
                    <a><button @click="askQuestion" class="button is-dark">Ask Our Community</button></a>
                </div>
            </div>
        </div>

        <div class="modal" :class="{ 'is-active': this.showLoginForm }">
            <div class="modal-background"></div>
            <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Login to Participate</p>
                <p>Need an Account? <a target="_blank" href="https://apps.wingubox.com/products">Create Account</a></p>
                <button @click="this.showLoginForm = false" class="delete ml-2" aria-label="close"></button>
            </header>
            <section class="modal-card-body">
                <form class="form" @submit.prevent="authenticate">
                    <div v-if="isIncorrectCredentials" class="notification is-danger is-light">
                        <button @click="this.isIncorrectCredentials = false" class="delete"></button>
                        Incorrect Email/Password!
                    </div>  
                    <div class="field">
                        <label class="label">Email</label>
                        <div class="control">
                            <input v-model="email" required class="input" type="email" placeholder="Enter your wingubox email">
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Password</label>
                        <div class="control">
                            <input v-model="password" required class="input" type="password" placeholder="Enter your password">
                        </div>
                    </div>
                    <footer class="modal-card-foot">
                    <button v-if="!isAuthenticating" class="button is-link">Login</button>
                    <button v-if="isAuthenticating" class="button is-link" disabled>Authenticating ...</button>
                    <button @click="this.showLoginForm = false" class="button">Cancel</button>
                    </footer>
                </form>
            </section>
            </div>
        </div>

        <div class="m-4">
            <div class="horizontal-scroll-container">
                <div v-if="currentApp != 'all'">
                    <span class="m-2 mt-4">
                        <button @click="filterQuestions('all')" class="button is-light">All Apps</button>
                    </span>
                </div>
                <div v-if="currentApp == 'all'">
                    <span v-if="currentApp != 'app'" class="m-2 mt-4">
                        <button class="button is-info">All Apps</button>
                    </span>
                </div>

                <div v-if="currentApp != 'payroll'">
                    <span class="m-2 mt-4">
                        <button @click="filterQuestions('payroll')" class="button is-light">Payroll</button>
                    </span>
                </div>
                <div v-if="currentApp == 'payroll'">
                    <span class="m-2 mt-4">
                        <button class="button is-info">Payroll</button>
                    </span>
                </div>

                <div v-if="currentApp != 'HRM'">
                    <span class="m-2 mt-4">
                        <button @click="filterQuestions('HRM')" class="button is-light">HRM Plus</button>
                    </span>
                </div>
                <div v-if="currentApp == 'HRM'">
                    <span class="m-2 mt-4">
                        <button class="button is-info">HRM Plus</button>
                    </span>
                </div>

                <div v-if="currentApp != 'leave'">
                    <span class="m-2 mt-4">
                        <button  @click="filterQuestions('leave')" class="button is-light">Leave</button>
                    </span>
                </div>
                <div v-if="currentApp == 'leave'">
                    <span class="m-2 mt-4">
                        <button class="button is-info">Leave</button>
                    </span>
                </div>

                <div v-if="currentApp != 'jobs'">
                    <span class="m-2 mt-4">
                        <button  @click="filterQuestions('jobs')" class="button is-light">Jobs</button>
                    </span>
                </div>
                <div v-if="currentApp == 'jobs'">
                    <span class="m-2 mt-4">
                        <button class="button is-info">Jobs</button>
                    </span>
                </div>

                <div v-if="currentApp != 'commerce'">
                    <span class="m-2 mt-4">
                        <button  @click="filterQuestions('commerce')" class="button is-light">Commerce</button>
                    </span>
                </div>
                <div v-if="currentApp == 'commerce'">
                    <span class="m-2 mt-4">
                        <button class="button is-info">Commerce</button>
                    </span>
                </div>

                <div v-if="currentApp != 'biometric'">
                    <span class="m-2 mt-4">
                        <button  @click="filterQuestions('biometric')" class="button is-light">Biometric</button>
                    </span>
                </div>
                <div v-if="currentApp == 'biometric'">
                    <span class="m-2 mt-4">
                        <button class="button is-info">Biometric</button>
                    </span>
                </div>
            </div>
        </div>

        <hr/>

        <div class="m-5">
            <div class="columns is-mobile is-multiline">
                <div class="column is-narrow">
                <span @click="toggleAll()" v-if="!this.$store.state.showAll">
                    <button class="button is-small is-light">All Questions</button>
                </span>
                <span @click="toggleAll()" v-if="this.$store.state.showAll">
                    <button class="button is-small is-info">All Questions</button>
                </span>
                </div>

                <div class="column is-narrow">
                <span @click="toggleAnswered()" v-if="!this.$store.state.showAnswered">
                    <button class="button is-small is-light">Answered</button>
                </span>
                <span @click="toggleAnswered()" v-if="this.$store.state.showAnswered">
                    <button class="button is-small is-success">Answered</button>
                </span>
                </div>

                <div class="column is-narrow">
                <span @click="toggleUnanswered()" v-if="!this.$store.state.showUnanswered">
                    <button class="button is-small is-light">Unanswered</button>
                </span>
                <span @click="toggleUnanswered()" v-if="this.$store.state.showUnanswered">
                    <button class="button is-small is-warning">Unanswered</button>
                </span>
                </div>
            </div>
        </div>

        <hr/>

        <div v-if="currentData.length == 0 && !loadingData" class="m-5">
            <p>No activities!</p>
        </div>

        <div v-if="loadingData" class="m-5">
            <div class='gfg g1'></div>
            <div class='gfg g2'></div>
            <div class='gfg g3'></div>
            <div class='gfg g4'></div>
            <div class='gfg g5'></div>
        </div>

        <div class="m-5" v-for="question in currentData " :key="question.id">
            <div class="columns is-mobile is-vcentered">
                <div class="column is-narrow">
                <span>
                    <figure class="image is-32x32">
                    <img v-if="question.app == 'Commerce'" class="is-rounded" src="../assets/apps_images/commerce.png">
                    <img v-if="question.app == 'Biometric'" class="is-rounded" src="../assets/apps_images/Biometric.png">
                    <img v-if="question.app == 'Payroll'" class="is-rounded" src="../assets/apps_images/payroll.png">
                    <img v-if="question.app == 'Leave'" class="is-rounded" src="../assets/apps_images/leave.png">
                    <img v-if="question.app == 'Jobs'" class="is-rounded" src="../assets/apps_images/jobs.png">
                    <img v-if="question.app == 'HRM'" class="is-rounded" src="../assets/apps_images/HRM.png">
                    </figure>
                </span>
                </div>
                <div class="column">
                    <span style="display: flex; align-items: center;">
                        <a  @click="moveToQuestion(question.question_title, question.id)">
                            <span v-if="question.answered" class="tag is-success">Answered</span>&nbsp;
                            <span v-if="!question.answered" class="tag is-warning">Unanswered</span>&nbsp;
                            <h2 style="display: inline;">{{ question.question_title }}</h2>
                        </a>
                    </span>
                </div>
            </div>
            <div class="columns is-mobile is-vcentered">
                <div class="column is-narrow">
                </div>
                <div class="column">
                    <p v-html="truncateDescription(question.question_description, 250)" class="question-description">
                    </p>
                </div>
            </div>

            <div class="columns is-mobile is-vcentered">
                <div class="column is-narrow">
                </div>
                <div class="column">
                    <span class="m-2" style="display: inline-block; color: rgb(44, 44, 208)">{{ transformEmailToUsername(question.asked_by) }}</span>
                    <span class="m-2" style="display: inline-block;">
                        <p style="display: flex; align-items: center;">
                            <i class="material-icons" style="font-size: 16px;">comment</i>&nbsp; {{question.number_of_responses}}
                        </p>
                    </span>
                    <span class="m-2" style="display: inline-block;"><p>Posted on {{ convertTime(question.asked_on) }}</p></span>
                </div>
            </div>
        </div>

        <hr/>

        <div class="m-4">
            <nav class="pagination is-rounded" role="navigation" aria-label="pagination">
                <div class="is-flex is-align-items-center">
                <button v-on:click="setPrevPage(this.currentPage)" class="button is-rounded">Prev</button>
                <ul class="pagination-list">
                    <li
                    v-for="page in visiblePages"
                    :key="page"
                    ><a v-on:click="setCurrentPage(page)" class="pagination-link" v-bind:class="{'is-current': currentPage == page}">{{ page }}</a></li>
                </ul>
                <a v-on:click="setNextPage(this.currentPage)" class="pagination-next">Next</a>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
import Fuse from 'fuse.js';

export default {
   data: function() {
       return {
           filterKey: '',
           currentApp: 'all',
           searchKey: '',
           currentPage: 1,  
           itemsPerPage: 5,
           showLoginForm: false,
           loadingData: true,
           email: '',
           password: '',
           isIncorrectCredentials: false,
           isAuthenticating: false
       }
   },

   mounted(){
    this.$store.dispatch('getQuestions')
    .then(()=>{
        this.loadingData = false
    })
    if (this.$store.state.token !== null){
        this.$store.dispatch('retrieveApiToken')
    }
   },

   computed: {
    loggedIn() {
        return this.$store.getters.loggedIn;
      },
    questions() {
        const allQuestions = this.$store.state.questions;

        if (this.$store.state.filterKey === 'answered') {
        return allQuestions.filter(question => question.answered === true);
        } else if (this.$store.state.filterKey === 'unanswered') {
        return allQuestions.filter(question => question.answered === false);
        } else {
        // Return all questions when filter type is not specified or is set to 'all'
        return allQuestions;
        }
    },
    suggestedQuestions() {
      const searchTerm = this.searchKey.trim();
      if (searchTerm === '') {
        return [];
      } else {
        const options = {
          keys: ['question_title', 'question_description', 'app'], // keys to search within
          threshold: 0.6, // Set the similarity threshold
        };

        const fuse = new Fuse(this.$store.state.suggestionQuestions, options);
        const results = fuse.search(searchTerm);
        return results.map(result => result.item);
      }
    },
    
    currentData() {
      let start = (this.currentPage - 1) * this.itemsPerPage;
      let end = start + this.itemsPerPage;
      return this.questions.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.questions.length / this.itemsPerPage);
    },
    visiblePages() {
      if (this.totalPages <= 4) {
          return Array.from({length: this.totalPages}, (_, i) => i + 1)
      } else {
          let start = this.currentPage - 1;
          let end = this.currentPage + 2;
          if (this.currentPage - 1 < 1) {
              start = 1
              end = 4
          } else if (this.currentPage + 2 > this.totalPages) {
              start = this.totalPages - 3
              end = this.totalPages
          }
          return Array.from({length: end - start + 1}, (_, i) => i + start)
      }
    }
   },

   methods: {
    authenticate(){
        this.isAuthenticating = true;
        this.$store.dispatch('authenticate', {
            email: this.email,
            password: this.password
        })
        .then(()=>{
            if (this.$store.state.accessToken){
                this.$router.push("/ask-question")
            }
            else{
                this.isIncorrectCredentials = true;
                this.isAuthenticating = false;
                this.email = '';
                this.password = '';
            }
        })
        .catch((error)=> {
            console.log(error)
        })
    },
    imagePath(app) {
      return `../assets/apps_images/${app}.png`;
    },
    toggleAll(){
        if (!this.$store.state.showAll){
            this.currentPage = 1
            this.$store.dispatch('toggleshowAll', true)
            this.$store.dispatch('toggleshowAnswered', false)
            this.$store.dispatch('toggleshowUnanswered', false)
            this.$store.dispatch('toggleFilterKey', '')
        }
    },
    toggleAnswered(){
        if (!this.$store.state.showAnswered){
            this.currentPage = 1
            this.$store.dispatch('toggleshowAnswered', true)
            this.$store.dispatch('toggleshowAll', false)
            this.$store.dispatch('toggleshowUnanswered', false)
            this.$store.dispatch('toggleFilterKey', 'answered')
        }
    },
    toggleUnanswered(){
        if (!this.$store.state.showUnanswered){
            this.currentPage = 1
            this.$store.dispatch('toggleshowUnanswered', true)
            this.$store.dispatch('toggleshowAll', false)
            this.$store.dispatch('toggleshowAnswered', false)
            this.$store.dispatch('toggleFilterKey', 'unanswered')
        }
    },
    convertTime(timestamp) {
        const date = new Date(timestamp);
        const options = { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' };
        const localDateString = date.toLocaleDateString('en-US', options);
        return localDateString;
    },
    moveToQuestion(title, id) {
        const decodedTitle = decodeURIComponent(title);
        const formattedTitle = decodedTitle.replace(/\s+/g, '-');
        const cleanTitle = formattedTitle.replace(/\?/g, '');
        const lowercaseTitle = cleanTitle.toLowerCase();
        this.$router.push('/' + lowercaseTitle + '/' + id);
    },
    askQuestion(){
        if (this.loggedIn){
            this.$router.push("/ask-question");
        }
        else{
            this.showLoginForm = true
        }
    },
    filterQuestions(app){
        this.currentApp = app
        this.currentPage = 1
        // console.log(this.currentApp)
        if (app == 'all'){
            this.$store.dispatch('getQuestions')
        }
        else{
            this.$store.dispatch('filterQuestions', app)
            .then(()=>{
                // console.log(this.$store.state.questions)
            })
            .catch((error) => {
                console.log(error)
            })
        }
    },
    setCurrentPage(page) {
      this.currentPage = page;
      console.log("currentPage: ", this.currentPage)
    },
    setNextPage(page){
      if (page < this.totalPages){
        this.currentPage = page+1;
      }
    },
    setPrevPage(page){
      if (page !==1){
        this.currentPage = page-1;
      }
    },
    truncateDescription(description, maxLength) {
        // Remove HTML tags using a regular expression
        const cleanedDescription = description.replace(/<[^>]+>/g, '');
        
        if (cleanedDescription.length <= maxLength) {
        return cleanedDescription;
        } else {
        return cleanedDescription.substring(0, maxLength) + '...';
        }
    },
    transformEmailToUsername(email) {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (emailRegex.test(email)) {
            const username = email.split('@')[0];
            const transformedUsername = 'WB_' + username;
            return transformedUsername;
        } else {
            const transformedUsername = 'WB_' + email;
            return transformedUsername;
        }
    }

   }
}
</script>

<style scoped>
@import 'bulma/css/bulma.min.css';
@import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
@import 'material-icons/iconfont/material-icons.css';

.container-fluid {
  /* position: absolute; */
  margin-top: -20px;
  left: 10%;
  padding-top: 4rem;
}

.auto-suggest-link:hover {
  background-color: lightgray;
}
.suggestion-list {
  max-height: 200px;
  overflow-y: auto;
}

.horizontal-scroll-container {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px 0;
}

/* Ensure that the buttons inside the container don't wrap */
.horizontal-scroll-container button {
    flex-shrink: 0;
    margin-right: 10px; /* Adjust spacing between buttons if needed */
}

.gfg {
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    left: calc(50% - 1em);
    border-radius: 1em;
    transform-origin: 1em 2em;
    animation: rotate;
    animation-iteration-count: infinite;
    animation-duration: 1.8s;
}

/* Rotation of loader dots */
@keyframes rotate {
    from {
        transform: rotateZ(0deg);
    }
    to {
        transform: rotateZ(360deg);
    }
}
.g1 {
    animation-delay: 0.1s;
    background-color: #0cb0f1;

}
.g2 {
    animation-delay: 0.2s;
    background-color: #0cb0f2;
}
.g3 {
    animation-delay: 0.3s;
    background-color: #0cb0f3;
}
.g4 {
    animation-delay: 0.4s;
    background-color: #0cb0f4;
}
.g5 {
    animation-delay: 0.5s;
    background-color: #0cb0f5;
}

</style>